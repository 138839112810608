import Flickity from 'flickity';
import 'flickity/dist/flickity.pkgd.js';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Flip,ScrollTrigger,Draggable);

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    logoSectionSlider();
    capabilitiesScroll();
  },
};

$(window).on('resize', function () {
  $('.gallery-wrp, .home-banner-slider').flickity('resize');
  verticalViewportAnimation();
  capabilitiesScroll();
});

$(window).on('load', function () {
  $('.gallery-wrp, .home-banner-slider',).flickity('resize');
  verticalViewportAnimation();
});

function verticalViewportAnimation() {
  if (matchMedia('screen and (max-width: 576px)').matches) {
    const $sections = $('.verticals-wrp-inner');

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.7, // 70% visibility
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                $(entry.target).addClass('selected');
            } else {
                $(entry.target).removeClass('selected');
            }
        });
    }, options);

    $sections.each(function() {
        observer.observe(this);
    });
  }
}

function logoSectionSlider() {
  $('.logo-slider-section').each(function() {
    if ($('.logo-slider-section .logo-slider').children().length > 6) {
      // Play with this value to change the speed
      let flickity = null;
      const slideshowEl = document.querySelector('.logo-slider-section .logo-slider');

      flickity = new Flickity(slideshowEl, {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        cellAlign:'center',
      });

      flickity.x = 0;
    }
  });
}

function capabilitiesScroll() {
  gsap.registerPlugin(ScrollTrigger);

  const section = document.querySelector('.capabilities-section.v1')

  if(section) {
    const cardsContainer = section.querySelector('.capabilities-cards-wrp');
    const cards = gsap.utils.toArray('.capability-panel');

   let xWidthValue = (-100 * (cards.length - 1)) - 15;

  gsap.to(cards, {
    xPercent: xWidthValue,
    ease: 'none',
    scrollTrigger: {
      trigger: '.capabilities-section-content',
      pin: true,
      scrub: 1,
      snap: {
        snapTo: 1 / (cards.length - 1),
        duration: { min: 0.2, max: 0.5 },
        ease: 'power1.inOut',
      },
      end: () => '+=' + cardsContainer.offsetWidth,
      onUpdate: self => {
        const progress = self.progress;
        const index = Math.round(progress * (cards.length - 1));
        cards.forEach((card, i) => {
          if (i === index) {
            card.classList.add('selected');
          } else {
            card.classList.remove('selected');
          }
        });
      },
    },
  });
  }
}
